import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  DeleteButton,
  Form,
  FormField,
  Loader,
  icons,
} from 'components'
import { useParams } from 'react-router'
import { useSdk } from 'sdk'
import { debounce, isInteger } from 'lodash'
import {
  dataUrlToFile,
  fileToBase64,
  formatPriceNumberWithDelimiter,
  getValidIteratorsOptions,
  getVoucherTerms,
  roundNumberWithTwoDecimals,
  serialize,
} from 'src/helpers'
import { t } from 'src/localization'
import { trpc } from 'src/trpc'
import { FormContainer, HelpHint } from 'src/styles'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { FormFieldsWrapper } from 'src/components/Form/Form'
import {
  FieldRow,
  FieldColumn,
  FormFooterButtonsContainer,
  FormPageContainer,
} from 'src/sdk/tw/Form'
import { MobileContext } from 'src/Context'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import { ServiceSelect } from 'views/CashRegister/NewReceipt/styles'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import NewService from '../Services/ServiceEditor/NewService'
import GiftCardImageEditorModal from './GiftCardImageEditorModal'
import MobileGiftCardImagePicker from './MobileGiftCardImagePicker'
import { Crisp } from 'crisp-sdk-web'
import { Tooltip2 } from 'src/components/Tooltip2/Tooltip2'
import Hashids from 'hashids'
import { getCurrentLanguage } from 'src/localization'
export const hasher = new Hashids('8bQ%q^h36iXZTWpzFoy')
const validationSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(
      t('translation.NewResource.validation-name-required')
    ),
    validAmount: Yup.string().matches(
      /^-?(?:\d+)(?:(\.|,)\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    value: Yup.string().matches(
      /^-?(?:\d+)(?:(\.|,)\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    price: Yup.string()
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test(
        'validPrice',
        'Cijena mora biti jednaka ili manja od vrijednosti predloška i jednaka ili veća od 0',
        function (value) {
          const { parent } = this
          const price = value || '0'

          return parseFloat(price?.toString()?.replace(',', '.')) >= 0
            ? parent?.voucherType === 'VOUCHER'
              ? parseFloat(price?.toString()?.replace(',', '.')) <=
                parseFloat(parent?.value?.toString().replace(',', '.'))
              : true
            : false
        }
      ),

    discountRate: Yup.string()
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test(
        'validDiscountAmount',
        'Popust ne može biti manji od 0 ni veći od 100',
        function (value) {
          const { parent } = this
          const discount = parseFloat(value || '0')

          return discount >= 0 && discount <= 100
        }
      ),
  })

type TemplateFormProps = {
  onSubmit: (values: any) => void
  initialValues: any
  loading: boolean
  isEdit?: boolean
  refetch?: any
  currency: string
  hasVat: boolean
  location?: any
  hasOnlinePaymentsEnabled: boolean
}

export const TemplateForm = (props: TemplateFormProps) => {
  const {
    onSubmit,
    initialValues,
    isEdit,
    loading,
    refetch,
    currency,
    hasVat,
    hasOnlinePaymentsEnabled,
    location,
  } = props

  const params = useParams<{
    selectedLocationId: string
    orgId: string
    id: string
    type: string
  }>()

  const { selectedLocationId, orgId, id, type } = params
  let formRef = useRef<FormikProps<any>>()
  const isMobile = useContext(MobileContext)
  const { navigateTo, appServices } = useSdk()
  const [showServicesModal, setShowServicesModal] = useState<boolean>(false)
  const [showImageEditModal, setShowImageEditModal] = useState<boolean>(false)

  const loyaltyType = isEdit ? initialValues?.voucherType.toLowerCase() : type
  const locationName = location?.name

  const optionsValidIterators = getValidIteratorsOptions()

  const deleteGiftCardTemplateMutation =
    trpc.giftCardTemplate_delete.useMutation()
  const createGiftCardTemplateItemMutation =
    trpc.giftCardTemplateItem_create.useMutation()
  const updateGiftCardTemplateMutation =
    trpc.giftCardTemplateVoucherTerms_update.useMutation()

  const handleDeleteGiftCardTemplate = async () => {
    try {
      const res = await deleteGiftCardTemplateMutation.mutateAsync({
        id: BigInt(id),
      })
      if (res) {
        appServices.toast.success('Predložak je uspješno obrisan')

        navigateTo.listTemplates(params)
      } else appServices.toast.danger('Predložak nije obrisan')
    } catch (e) {
      appServices.toast.danger('Predložak nije obrisan')
    }
  }

  const handleCreateGiftCardTemplateItem = async ({
    itemName,
    serviceId,
    serviceTypeId,
    description,
    discountRate,
    quantity,
  }) => {
    try {
      const res = await createGiftCardTemplateItemMutation.mutateAsync({
        loyaltyType,
        locationId: BigInt(selectedLocationId),
        orgId,
        giftCardTemplateId: BigInt(id),
        itemName,
        serviceId,
        serviceTypeId,
        description,
        discountRate,
        quantity,
      })

      if (res) {
        appServices.toast.success('Usluga uspješno kreirana')
        await updateGiftCardTemplateMutation.mutateAsync({
          id: BigInt(formRef?.current?.values?.id),
          voucherTerms: getVoucherTerms(
            {
              ...formRef?.current?.values,
              value: res?.value,
              price: res?.price,
              items: [
                ...formRef?.current?.values?.items,
                {
                  itemName,
                  serviceId,
                  serviceTypeId,
                  description,
                  discountRate,
                  quantity,
                },
              ],
            },
            locationName,
            currency
          ),
        })
        await refetch()
      } else appServices.toast.danger('Usluga nije kreirana')
    } catch (e) {
      appServices.toast.danger('Usluga nije kreirana')
    }
  }

  const MobileOption = props => {
    return (
      <div className="flex items-center">
        <p data-cy={'mobileSelect_value'}>{props.data?.name}</p>
      </div>
    )
  }
  const selectComponents = {
    MobileOption,
  }

  const handleValueChange = (name, value, values, form) => {
    if (
      name === 'price' ||
      name === 'validIterator' ||
      name === 'validAmount' ||
      name === 'discountRate'
    ) {
      form.setFieldValue(
        'voucherTerms',
        name === 'discountRate' && loyaltyType === 'voucher'
          ? values?.voucherTerms
          : getVoucherTerms(values, locationName, currency)
      )
      form.setFieldValue('autoGenerateTermsOfUse', true)
    }
    if (name === 'price') {
      if (
        !hasOnlinePaymentsEnabled ||
        (isEdit && !imageConfig?.fullImageId) ||
        Number(formRef.current?.values?.price || 0) < 10
      ) {
        form.setFieldValue('onlinePurchaseEnabled', false)
      }
    }
  }

  const imageConfig = JSON.parse(initialValues?.giftCardImageConfig || 'null')

  return (
    <>
      {showImageEditModal ? (
        isMobile ? (
          <MobileGiftCardImagePicker
            handleOnCloseEvent={() => setShowImageEditModal(false)}
            giftCardTemplate={initialValues}
            refetch={refetch}
            locationName={locationName}
            currency={currency}
          />
        ) : (
          <GiftCardImageEditorModal
            handleOnCloseEvent={() => setShowImageEditModal(false)}
            giftCardTemplate={initialValues}
            refetch={refetch}
            locationName={locationName}
            currency={currency}
          />
        )
      ) : null}
      <FormPageContainer>
        {loading ? <Loader isComponent /> : null}
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...initialValues,
            valueCurrency: currency,
            priceCurrency: currency,
            validIterator: optionsValidIterators?.find(
              x => x?.id === initialValues?.validIterator
            ),
            items: [
              ...initialValues?.items?.filter(x => x?.serviceId),
              ...initialValues?.items?.filter(x => x?.serviceTypeId),
            ],
            autoGenerateTermsOfUse: false,
            voucherType: isEdit
              ? initialValues?.voucherType
              : loyaltyType?.toUpperCase(),
            percentage: '%',
          }}
          validationSchema={validationSchema(t)}
          onValueChange={handleValueChange}
        >
          {form => {
            formRef.current = form
            return (
              <FormFieldsWrapper>
                <FormContainer>
                  <FieldRow
                    style={{
                      flexDirection: isMobile ? 'column-reverse' : 'row',
                    }}
                  >
                    <FieldColumn
                      style={{
                        flexDirection: 'column',
                        maxWidth: isMobile ? '100%' : '60%',
                      }}
                    >
                      <FieldRow>
                        <FieldColumn>
                          <p className="w-[150px] mr-[14px]">Vrsta predloška</p>
                          <span className="text-gray-400">
                            {loyaltyType === 'giftcard'
                              ? 'Poklon bon'
                              : 'Personalizirani program'}
                          </span>
                        </FieldColumn>
                      </FieldRow>

                      <FieldRow>
                        <FieldColumn className="max-w-[150px]">
                          <span>Naziv</span>
                        </FieldColumn>
                        <FieldColumn style={{ flexDirection: 'column' }}>
                          <FormField.Text
                            forceAutoFocus={!isMobile}
                            autoCapitalize="on"
                            name="name"
                          />
                        </FieldColumn>
                      </FieldRow>

                      <FieldRow>
                        <FieldColumn className="max-w-[150px]">
                          <span>Valjanost</span>
                        </FieldColumn>
                        <FieldColumn>
                          <div className="max-w-[100px] mr-[8px]">
                            <FormField.Text name="validAmount" />
                          </div>

                          <div>
                            <FormField.Select
                              helpText={t(
                                'translation.ProfessionEditorForm.EmployeesHint'
                              )}
                              closeMenuOnSelect={true}
                              name="validIterator"
                              options={optionsValidIterators}
                              components={selectComponents}
                              selectMinWidth={'140px'}
                              isClearable={false}
                            />
                          </div>
                        </FieldColumn>
                      </FieldRow>

                      <FieldRow>
                        <FieldColumn className="max-w-[150px]">
                          <p>Vrijednost</p>
                        </FieldColumn>
                        <FieldColumn>
                          <div className="max-w-[100px]">
                            <FormField.Text
                              name="value"
                              disabled={loyaltyType === 'voucher'}
                              shouldDisplayZeroAsValue
                              onChange={(e: any) => {
                                if (isNaN(parseFloat(e))) return

                                if (loyaltyType === 'giftcard') {
                                  form.setFieldValue(
                                    'price',
                                    !!parseFloat(form?.values?.discountRate)
                                      ? roundNumberWithTwoDecimals(
                                          parseFloat(
                                            form?.values?.value
                                              .toString()
                                              .replace(',', '.') || 0
                                          ) -
                                            parseFloat(
                                              form?.values?.value.replace(
                                                ',',
                                                '.'
                                              )
                                            ) *
                                              roundNumberWithTwoDecimals(
                                                parseFloat(
                                                  form?.values?.discountRate
                                                    .toString()
                                                    .replace(',', '.') || 0
                                                ) / 100
                                              )
                                        )
                                      : e
                                  )
                                  form.validateForm()
                                }
                              }}
                            />
                          </div>

                          <div className="max-w-[80px] ml-[4px] flex">
                            <FormField.Text disabled name="valueCurrency" />
                            <Tooltip2 helpText="Ukupna vrijednost usluga ili proizvoda koju je moguće zamijeniti za poklon bon">
                              <icons.Info
                                size="smaller"
                                className="text-zoyya-secondaryDark"
                                style={{ marginLeft: '10px', flexShrink: 0 }}
                              />
                            </Tooltip2>
                          </div>
                        </FieldColumn>
                      </FieldRow>

                      <FieldRow>
                        <FieldColumn className="max-w-[150px]">
                          <p>Cijena</p>
                        </FieldColumn>
                        <FieldColumn>
                          <div className="max-w-[100px]">
                            <FormField.Text
                              name="price"
                              disabled={loyaltyType === 'voucher' && !isEdit}
                              shouldDisplayZeroAsValue
                              onChange={(e: any) => {
                                if (isNaN(parseFloat(e))) return

                                if (loyaltyType === 'voucher') {
                                  const discount = e
                                    ? 100 *
                                      (1 -
                                        parseFloat(
                                          e?.toString().replace(',', '.')
                                        ) /
                                          parseFloat(
                                            form?.values?.value
                                              ?.toString()
                                              .replace(',', '.')
                                          ))
                                    : 0

                                  if (discount > 0) {
                                    form.setFieldValue('discountRate', discount)
                                    form.validateForm()
                                  }
                                } else {
                                  if (
                                    parseFloat(e || '0') >=
                                    parseFloat(form?.values?.value || '0')
                                  ) {
                                    form.setFieldValue('value', e)
                                    form.setFieldValue('discountRate', 0)
                                  }

                                  const discount = e
                                    ? 100 *
                                      (1 -
                                        parseFloat(
                                          e?.toString().replace(',', '.')
                                        ) /
                                          parseFloat(
                                            form?.values?.value
                                              ?.toString()
                                              .replace(',', '.')
                                          ))
                                    : 0

                                  if (discount > 0) {
                                    form.setFieldValue('discountRate', discount)
                                    form.validateForm()
                                  }
                                }
                              }}
                            />
                          </div>

                          <div className="max-w-[80px] ml-[4px] flex">
                            <FormField.Text disabled name="priceCurrency" />
                            <Tooltip2 helpText="Prodajna cijena poklon bona">
                              <icons.Info
                                size="smaller"
                                className="text-zoyya-secondaryDark"
                                style={{ marginLeft: '10px', flexShrink: 0 }}
                              />
                            </Tooltip2>
                          </div>
                        </FieldColumn>
                      </FieldRow>
                      <FieldRow>
                        <FieldColumn className="max-w-[150px]">
                          <p>Popust</p>
                        </FieldColumn>
                        <FieldColumn>
                          <div className="max-w-[100px]">
                            <FormField.Text
                              name="discountRate"
                              shouldDisplayZeroAsValue
                              // parseValue={e => {
                              //   return !form.values?.discountRate
                              //     ? parseFloat('0')
                              //     : roundNumberWithTwoDecimals(
                              //         parseFloat(form.values?.discountRate)
                              //       )
                              // }}
                              onChange={e => {
                                if (e) {
                                  const discount =
                                    parseFloat(
                                      form?.values.value
                                        ?.toString()
                                        .replace(',', '.')
                                    ) *
                                    (parseFloat(
                                      form?.values?.discountRate
                                        .toString()
                                        .replace(',', '.') || 0
                                    ) /
                                      100)

                                  const price =
                                    parseFloat(
                                      form?.values.value
                                        ?.toString()
                                        .replace(',', '.')
                                    ) - discount
                                  form.setFieldValue('price', price || '0')
                                  form.validateForm()
                                }
                              }}
                            />
                          </div>

                          <div className="max-w-[80px] ml-[4px] flex">
                            <FormField.Text
                              disabled
                              name="percentage"
                              className="text-lg p-4"
                              style={
                                {
                                  fontSize: 18,
                                  textAlign: 'center',
                                } as any
                              }
                            />
                            <Tooltip2 helpText="Popust koji kupac ostvaruje na ukupnu vrijednost poklon bona">
                              <icons.Info
                                size="smaller"
                                className="text-zoyya-secondaryDark"
                                style={{ marginLeft: '10px', flexShrink: 0 }}
                              />
                            </Tooltip2>
                          </div>
                        </FieldColumn>
                      </FieldRow>

                      {hasVat ? (
                        <FieldRow style={{ alignItems: 'center' }}>
                          <FieldColumn className="lg:max-w-[150px]">
                            <p>PDV (%)</p>
                          </FieldColumn>
                          <FieldColumn style={{ flexDirection: 'row' }}>
                            <div className="max-w-[100px]">
                              <FormField.Text
                                disabled
                                name="vatRate"
                                shouldDisplayZeroAsValue
                              />
                            </div>
                            <div className="max-w-[80px] ml-[4px] flex ">
                              <FormField.Text
                                disabled
                                name="percentage"
                                className="text-lg p-4"
                                style={
                                  {
                                    fontSize: 18,
                                    textAlign: 'center',
                                  } as any
                                }
                              />
                              <Tooltip2 helpText="Na računu za prodaju poklon bona bit će iskazan PDV">
                                <icons.Info
                                  size="smaller"
                                  className="text-zoyya-secondaryDark"
                                  style={{ marginLeft: '10px', flexShrink: 0 }}
                                />
                              </Tooltip2>
                            </div>
                          </FieldColumn>
                        </FieldRow>
                      ) : null}
                      <FieldRow
                        style={{ alignItems: !isMobile ? 'center' : undefined }}
                      >
                        <FieldColumn className="lg:max-w-[150px] lg:min-w-[150px]">
                          <p>Online prodaja</p>
                        </FieldColumn>
                        <div className="flex gap-2 flex-1">
                          <FormField.Checkbox
                            name="onlinePurchaseEnabled"
                            type="checkbox"
                            style={{ flex: 0 }}
                            disabled={
                              !hasOnlinePaymentsEnabled ||
                              (isEdit && !imageConfig?.fullImageId) ||
                              Number(form?.values?.price || 0) < 10
                            }
                          />
                          {!hasOnlinePaymentsEnabled ? (
                            <div className="flex items-center">
                              <HelpHint className="!pt-0">
                                <icons.Info
                                  size="smaller"
                                  style={{ marginRight: '10px', flexShrink: 0 }}
                                />

                                <div>
                                  <span>
                                    {
                                      'Online prodaja nije dostupna jer nedostaje konfiguracija za online plaćanja i izdavanje računa. Molimo da kontaktirate Zoyya podršku'
                                    }
                                  </span>
                                  <span
                                    className="text-zoyya-primary hover:underline cursor-pointer"
                                    onClick={() => {
                                      Crisp.chat.open()
                                    }}
                                  >
                                    {'putem chata u aplikaciji.'}
                                  </span>
                                </div>
                              </HelpHint>
                            </div>
                          ) : isEdit && !imageConfig?.fullImageId ? (
                            <div className="flex items-center">
                              <HelpHint className="!pt-0">
                                <icons.Info
                                  size="smaller"
                                  style={{ marginRight: '10px', flexShrink: 0 }}
                                />

                                <div>
                                  <span>
                                    {
                                      'Online prodaja nije dostupna jer nedostaje slika predloška'
                                    }
                                  </span>
                                </div>
                              </HelpHint>
                            </div>
                          ) : Number(form?.values?.price || 0) < 10 &&
                            !(loyaltyType === 'voucher' && !isEdit) ? (
                            <div className="flex items-center">
                              <HelpHint className="!pt-0">
                                <icons.Info
                                  size="smaller"
                                  style={{ marginRight: '10px', flexShrink: 0 }}
                                />

                                <div>
                                  <span>
                                    {
                                      'Cijena mora biti veća od ili jednaka 10 EUR za omogućivanje online prodaje'
                                    }
                                  </span>
                                </div>
                              </HelpHint>
                            </div>
                          ) : form?.values?.onlinePurchaseEnabled && isEdit ? (
                            <div className="p-[10px] flex justify-between border rounded-[4px] items-center lg:max-w-full max-w-[calc(100%-55px)]">
                              <div
                                className="overflow-hidden text-ellipsis whitespace-nowrap text-zoyya-primary hover:text-zoyya-primaryDark cursor-pointer"
                                onClick={() => {
                                  window.open(
                                    `${
                                      import.meta.env.VITE_CLIENT_URL
                                    }/${getCurrentLanguage()}/${location?.slug}/store?giftCardTemplateId=${hasher.encode(
                                      initialValues?.id
                                    )}`,
                                    '_blank',
                                    'noreferrer'
                                  )
                                }}
                              >
                                {`${
                                  import.meta.env.VITE_CLIENT_URL
                                }/${getCurrentLanguage()}/${location?.slug}/store?giftCardTemplateId=${hasher.encode(
                                  initialValues?.id
                                )}`}
                              </div>

                              <ButtonTW
                                variant={'text'}
                                label=""
                                tooltip={'Kopiraj'}
                                className={'!h-[30px]'}
                                icon={<icons.Copy size={'small'} />}
                                size={'small'}
                                onClick={e => {
                                  e.preventDefault()
                                  navigator.clipboard.writeText(
                                    `${
                                      import.meta.env.VITE_CLIENT_URL
                                    }/${getCurrentLanguage()}/${location?.slug}/store?giftCardTemplateId=${hasher.encode(
                                      initialValues?.id
                                    )}`
                                  )
                                  appServices.toast.success(
                                    t(
                                      'translation.AppointmentModal.toast-link-saved-to-clipboard'
                                    )
                                  )
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </FieldRow>
                    </FieldColumn>

                    {initialValues?.id /* && !isMobile */ ? (
                      <FieldColumn>
                        <div className="flex flex-col w-[395px] justify-end items-center lg:items-end flex-1 ">
                          <img
                            className={`shadow-md rounded-[35px] !w-[395px] object-contain !lg:h-[250px] ${
                              !imageConfig?.fullImageId
                                ? '!lg:max-h-[250px] !h-[250px]'
                                : '!lg:max-h-[250px] !h-auto'
                            } mb-4`}
                            src={
                              imageConfig?.fullImageId
                                ? `${
                                    import.meta.env.VITE_API_URL
                                  }/api/giftCardTemplate/${initialValues?.id}/${
                                    imageConfig.fullImageId
                                  }`
                                : '/assets/noImage.svg'
                            }
                          />
                          <div className="flex gap-2 justify-end">
                            {imageConfig?.fullImageId ? (
                              <ButtonTW
                                label={isMobile ? 'Preuzmi' : 'Preuzmi sliku'}
                                variant="secondary"
                                onClick={async () => {
                                  const a = document.createElement('a')
                                  a.download = `${initialValues?.name}.png`
                                  const file = await dataUrlToFile(
                                    `${
                                      import.meta.env.VITE_API_URL
                                    }/api/giftCardTemplate/${initialValues?.id}/${
                                      imageConfig.fullImageId
                                    }`,
                                    `${initialValues?.name}.png`
                                  )
                                  const dataUrl = (await fileToBase64(
                                    file
                                  )) as string
                                  a.target = '_blank'

                                  a.href = dataUrl
                                  a.click()
                                }}
                              />
                            ) : null}

                            <ButtonTW
                              label={
                                imageConfig?.fullImageId
                                  ? isMobile
                                    ? 'Odaberi sliku'
                                    : 'Uredi sliku'
                                  : 'Dodaj sliku'
                              }
                              variant="primaryOutline"
                              onClick={() => {
                                setShowImageEditModal(true)
                              }}
                            />
                          </div>
                        </div>
                      </FieldColumn>
                    ) : null}
                  </FieldRow>

                  <FieldRow style={{ alignItems: 'flex-start' }}>
                    <FieldColumn className="lg:max-w-[150px]">
                      <p>Opis</p>
                    </FieldColumn>
                    <FieldColumn style={{ flexDirection: 'column' }}>
                      <FormField.RichTextEditor
                        val={
                          !form.values.voucherDesc
                            ? '<p></p>'
                            : Array.isArray(form.values.voucherDesc)
                            ? serialize(form.values.voucherDesc)
                            : form.values.voucherDesc
                        }
                        name="voucherDesc"
                        showGenerateButton={!!form.values.id}
                        generateButtonProps={
                          form.values.id
                            ? {
                                orgId,
                                fieldName: 'voucherDesc',
                                entity: 'giftCardTemplateDescription',
                                locationId: BigInt(location.id),
                                giftCardTemplateId: BigInt(form.values.id),
                              }
                            : undefined
                        }
                      />
                    </FieldColumn>
                  </FieldRow>

                  <FieldRow style={{ alignItems: 'flex-start' }}>
                    <FieldColumn className="lg:max-w-[150px]">
                      <p>Uvjeti korištenja</p>
                    </FieldColumn>
                    <FieldColumn style={{ flexDirection: 'column' }}>
                      <FormField.RichTextEditor
                        val={
                          !form.values.voucherTerms
                            ? '<p></p>'
                            : Array.isArray(form.values.voucherTerms)
                            ? serialize(form.values.voucherTerms)
                            : form.values.voucherTerms
                        }
                        name="voucherTerms"
                        onKeyPress={e =>
                          form.setFieldValue('autoGenerateTermsOfUse', false)
                        }
                      />
                    </FieldColumn>
                  </FieldRow>

                  {/* <FieldRow style={{ alignItems: 'flex-start' }}>
                    <FieldColumn>
                      <FormField.Checkbox
                        label={'Omogući darivanje'}
                        name="isTransferable"
                        type={'checkbox'}
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <FormField.Checkbox
                        label={'Jednokratno'}
                        name="singleUse"
                        type={'checkbox'}
                      />
                    </FieldColumn>
                  </FieldRow> */}

                  <ServiceList
                    setShowServicesModal={setShowServicesModal}
                    locationName={locationName}
                    values={form?.values}
                    isEdit={isEdit}
                    type={loyaltyType}
                    currency={currency}
                    refetch={refetch}
                    isAddButtonDisabled={
                      !isEdit
                        ? true
                        : loyaltyType === 'giftcard'
                        ? !form?.values?.name ||
                          !form?.values?.validAmount ||
                          !form?.values?.validIterator
                        : !form?.values?.name ||
                          !form?.values?.validAmount ||
                          !form?.values?.validIterator
                    }
                  />
                  {isMobile && isEdit ? (
                    <div className="mt-10 w-fit ml-auto">
                      <DeleteButton
                        withConfirm
                        onConfirm={handleDeleteGiftCardTemplate}
                        confirmationMessage={t(
                          'translation.NewClientContainer.title-confirmResourceDelete'
                        )}
                      />
                    </div>
                  ) : null}
                </FormContainer>
              </FormFieldsWrapper>
            )
          }}
        </Form>
      </FormPageContainer>

      {!isMobile && !loading ? (
        <div className="flex p-4 w-full border border-t-1 shadow-[rgba(0,0,15,0.5)_0px_-6px_6px_-8px] pr-[20px] ">
          <div className="flex justify-between w-full">
            <div className="flex gap-8">
              <Button
                footerButton
                buttonType={'text'}
                name="closeForm"
                type={'button'}
                label={t('translation.ResourceEditorForm.button-close')}
                onClick={() => {
                  navigateTo.listTemplates(params)
                }}
              />
              <Button
                onClick={() => formRef?.current?.submitForm()}
                className="w-[150px] flex !justify-center"
                buttonType={'primary'}
                name="saveChanges"
                label={t('translation.ResourceEditorForm.button-save')}
              />
            </div>
            {isEdit ? (
              <DeleteButton
                withConfirm
                onConfirm={handleDeleteGiftCardTemplate}
                confirmationMessage={t(
                  'translation.NewClientContainer.title-confirmResourceDelete'
                )}
              />
            ) : null}
          </div>
        </div>
      ) : null}

      {isMobile ? (
        <FormFooterButtonsContainer>
          <Button
            footerButton
            buttonType={'text'}
            name="closeForm"
            type={'button'}
            label={t('translation.ResourceEditorForm.button-close')}
            onClick={() => {
              navigateTo.listTemplates(params)
            }}
          />
          <Button
            onClick={() => {
              formRef?.current?.submitForm()
            }}
            buttonType={'primary'}
            name="saveChanges"
            label={t('translation.ResourceEditorForm.button-save')}
          />
        </FormFooterButtonsContainer>
      ) : null}

      {showServicesModal ? (
        <ServicesModal
          setShowServicesModal={setShowServicesModal}
          currency={currency}
          onClickItem={handleCreateGiftCardTemplateItem}
          loyaltyType={loyaltyType}
          formRef={formRef.current}
        />
      ) : null}
    </>
  )
}

export default TemplateForm

type ServiceListProps = {
  // items: RouterOutput['giftCard_findOne']['items']
  values: any
  setShowServicesModal(arg1: boolean): any
  type: string
  currency: string
  refetch: any
  isAddButtonDisabled: boolean
  isEdit?: boolean
  locationName?: string
}

const ServiceList = (props: ServiceListProps) => {
  const {
    values,
    setShowServicesModal,
    type,
    currency,
    refetch,
    isAddButtonDisabled,
    isEdit,
    locationName,
  } = props
  const { appServices } = useSdk()
  const params = useParams<{
    selectedLocationId: string
    orgId: string
  }>()

  const { selectedLocationId, orgId } = params

  const deleteGiftCardTemplateItemMutation =
    trpc.giftCardTemplateItem_delete.useMutation()
  const updateGiftCardTemplateMutation =
    trpc.giftCardTemplateVoucherTerms_update.useMutation()

  const handleDeleteGiftCardTemplateItem = async (id: bigint) => {
    try {
      const res = await deleteGiftCardTemplateItemMutation.mutateAsync({
        locationId: BigInt(selectedLocationId),
        orgId,
        loyaltyType: type,
        id: BigInt(id),
        giftCardTemplateId: values?.id,
      })
      if (res) {
        appServices.toast.success('Usluga uspješno obrisana')
        await updateGiftCardTemplateMutation.mutateAsync({
          id: BigInt(values?.id),
          voucherTerms: getVoucherTerms(
            {
              ...values,
              value: res?.value,
              price: res?.price,
              items: values?.items?.filter(x => x?.id !== id),
            },
            locationName,
            currency
          ),
        })
        await refetch()
      } else appServices.toast.danger('Usluga nije obrisana')
    } catch (e) {
      appServices.toast.danger('Usluga nije obrisana')
    }
  }

  return (
    <div className="mt-[30px]">
      <p className="text-[18px] mb-[10px] flex justify-between items-center">
        <div>Odobrene usluge </div>
        <ButtonTW
          label="+ Dodaj uslugu"
          variant={isAddButtonDisabled ? 'primary' : 'primaryOutline'}
          className="flex-0 lg:mt-[20px]"
          size="small"
          disabled={isAddButtonDisabled}
          onClick={() => {
            setShowServicesModal(true)
          }}
        />
      </p>

      <div className="hidden lg:block bg-zoyya-grayLighter rounded-sm border border-zoyya-border pr-3 pl-[1.143rem]">
        <div className="rounded-md w-full">
          <div className="w-full flex  py-2">
            {type === 'giftcard' ? (
              <>
                <p className="w-[300px] text-sm text-zoyya-secondaryDark">
                  Vrsta usluge
                </p>
                <p className="mr-auto text-sm text-zoyya-secondaryDark">
                  Usluga
                </p>
                <div className="w-20"></div>
              </>
            ) : (
              <>
                <p className="grow text-sm text-zoyya-secondaryDark">Usluga</p>
                <p className="w-[30px] mr-[10px] text-sm text-zoyya-secondaryDark">
                  Kol
                </p>
                <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                  Cijena
                </p>
                <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                  Vrijednost
                </p>
                <p className="w-[40px] mr-[10px] text-center text-sm text-zoyya-secondaryDark">
                  %
                </p>
                <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                  Neto
                </p>
                <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                  Iznos
                </p>
                <div className="w-10"></div>
              </>
            )}
          </div>

          {values?.items?.length ? (
            values?.items?.map(x => (
              <div
                key={x?.id.toString()}
                className="w-full flex py-2 border-t border-zoyya-border "
              >
                {type === 'giftcard' ? (
                  <>
                    <p className="w-[300px]">
                      {x?.serviceTypeId ? x?.itemName : ''}
                    </p>
                    <p className="mr-auto">
                      {x?.serviceId ? x?.itemName : 'Sve usluge'}
                    </p>
                    <div
                      className="w-20 flex justify-center cursor-pointer"
                      onClick={() => {
                        handleDeleteGiftCardTemplateItem(x?.id)
                      }}
                    >
                      <icons.Trash
                        className="text-zoyya-accent5"
                        size="smaller"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <p className="grow text-[13px] mr-[10px]">{x?.itemName}</p>
                    <p className="min-w-[30px] mr-[10px] text-[13px]">
                      {x?.quantity || ''}
                    </p>
                    <p className="min-w-[70px] mr-[10px] text-[13px]">
                      {`${
                        formatPriceNumberWithDelimiter(
                          parseFloat(x?.Service?.price)
                        ) || ''
                      }`}
                    </p>
                    <p className="min-w-[70px] mr-[10px] text-[13px]">{`${formatPriceNumberWithDelimiter(
                      x?.quantity * parseFloat(x?.Service?.price)
                    )}`}</p>
                    <p className="min-w-[40px] mr-[10px] text-center text-[13px]">
                      {formatPriceNumberWithDelimiter(
                        x?.discountRate * 100 || 0,
                        false
                      ) || ''}
                    </p>
                    <p className="min-w-[70px] mr-[10px] text-[13px]">
                      {formatPriceNumberWithDelimiter(
                        parseFloat(x?.Service?.price) -
                          roundNumberWithTwoDecimals(
                            parseFloat(x?.Service?.price) * x?.discountRate
                          )
                      )}
                    </p>
                    <p className="min-w-[80px] mr-[10px] text-[13px]">
                      {`${formatPriceNumberWithDelimiter(
                        (x?.Service?.price -
                          roundNumberWithTwoDecimals(
                            parseFloat(x?.Service?.price) * x?.discountRate
                          )) *
                          x?.quantity
                      )} ${currency}`}
                    </p>
                    <div
                      className="min-w-10 flex justify-center cursor-pointer"
                      onClick={() => {
                        handleDeleteGiftCardTemplateItem(x?.id)
                      }}
                    >
                      <icons.Trash
                        className="text-zoyya-accent5"
                        size="smaller"
                      />
                    </div>
                  </>
                )}
              </div>
            ))
          ) : isEdit && type === 'giftcard' ? (
            <div className="w-full flex py-2 border-t border-zoyya-border ">
              <>
                <p className="w-[300px]"></p>
                <p className="mr-auto">{'Sve usluge'}</p>
              </>
            </div>
          ) : null}
        </div>
      </div>

      {type === 'giftcard' ? (
        <>
          {values?.items?.length ? (
            <div className="">
              <table className="lg:hidden min-w-full rounded-lg bg-white p-4">
                <thead>
                  <tr className="bg-zoyya-lightBackground">
                    <th className="p-3 text-left font-medium">Vrsta usluge</th>
                    <th className="w-2/4 p-3 text-left font-medium">Usluga</th>
                    <th className="w-[40px] p-3 text-left font-medium"></th>
                  </tr>
                </thead>
                <tbody>
                  {values?.items?.map(service => (
                    <tr key={service?.id} className="border-b">
                      <td className="p-3">
                        {service?.serviceTypeId ? service.itemName : ''}
                      </td>
                      <td className="p-3">
                        {service?.serviceId ? service.itemName : 'Sve usluge'}
                      </td>
                      <td className="">
                        <div
                          className="flex justify-center cursor-pointer"
                          onClick={() => {
                            handleDeleteGiftCardTemplateItem(service?.id)
                          }}
                        >
                          <icons.Trash
                            className="text-zoyya-accent5"
                            size="smaller"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="py-5 text-center text-secondary-400 lg:hidden">
              Ovaj poklon bon se može iskoristiti za bilo koju uslugu/usluge u
              vrijednosti do {values?.value + ' ' + currency}
            </div>
          )}
        </>
      ) : (
        //voucher services
        <div className="flex flex-col gap-3 lg:hidden">
          {values?.items?.length
            ? values?.items?.map(service => (
                <div
                  key={service?.id.toString()}
                  className="cursor-pointer rounded-lg border p-4 "
                >
                  <div className="flex mb-2 justify-between items-center gap-2">
                    <h3 className="text-lg font-semibold">
                      {service?.itemName}
                    </h3>
                    <div
                      className="min-w-10 flex justify-center cursor-pointer"
                      onClick={() => {
                        handleDeleteGiftCardTemplateItem(service?.id)
                      }}
                    >
                      <icons.Trash
                        className="text-zoyya-accent5"
                        size="smaller"
                      />
                    </div>
                  </div>

                  <div className="mt-2 flex flex-col space-y-2 text-sm">
                    <div className="flex items-center justify-between">
                      <span>Količina:</span>
                      <span className="text-md ">{service.quantity}</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Cijena:</span>
                      <span className="text-md ">
                        {`${
                          formatPriceNumberWithDelimiter(
                            parseFloat(service?.Service?.price)
                          ) || ''
                        }`}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Vrijednost:</span>
                      <span className="whitespace-nowrap text-md ">
                        {`${formatPriceNumberWithDelimiter(
                          service?.quantity *
                            parseFloat(service?.Service?.price)
                        )}`}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Popust:</span>
                      <span className="whitespace-nowrap text-md ">
                        {formatPriceNumberWithDelimiter(
                          service?.discountRate * 100 || 0,
                          false
                        ) + '%' || ''}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Neto:</span>

                      <span className="text-md ">
                        {formatPriceNumberWithDelimiter(
                          parseFloat(service?.Service?.price) -
                            roundNumberWithTwoDecimals(
                              parseFloat(service?.Service?.price) *
                                service?.discountRate
                            )
                        )}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Iznos:</span>
                      <span className="whitespace-nowrap text-md ">
                        {`${formatPriceNumberWithDelimiter(
                          (service?.Service?.price -
                            roundNumberWithTwoDecimals(
                              parseFloat(service?.Service?.price) *
                                service?.discountRate
                            )) *
                            service?.quantity
                        )} ${currency}`}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      )}
    </div>
  )
}

type ServiceModalProps = {
  setShowServicesModal: (arg0: boolean) => void
  currency: string
  onClickItem: any
  loyaltyType: string
  formRef: any
}

const ServicesModal = (props: ServiceModalProps) => {
  const { setShowServicesModal, currency, onClickItem, loyaltyType, formRef } =
    props
  const params = useParams<{
    selectedLocationId: string
    orgId: string
  }>()
  const { selectedLocationId, orgId } = params

  const [showAddServiceForm, setShowAddServiceForm] = useState<boolean>(false)
  const [filteredServiceTypes, setFilteredServiceTypes] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)

  const [selectedService, setSelectedService] = useState<any>(null)
  const [inputValue, setInputValue] = useState<string>('')

  const { data: serviceTypes, isLoading: serviceTypesLoading } =
    trpc.serviceType_findMany.useQuery({
      orgId: orgId,
      locationId: BigInt(selectedLocationId),
    })

  const globalSearchMemo = useMemo(() => {
    return (searchInput, serviceTypes) => {
      if (searchInput === '') {
        return serviceTypes
      }
      let filteredData = serviceTypes
        ?.filter(element => {
          return element.Services.some(service =>
            searchInput
              ? service.name?.toLowerCase().includes(searchInput.toLowerCase())
              : service
          )
        })
        .map(element => {
          let newService = Object.assign({}, element) // copies element
          return {
            ...element,
            Services: newService.Services.filter(service =>
              searchInput
                ? service.name
                    ?.toLowerCase()
                    .includes(searchInput.toLowerCase())
                : service
            ),
          }
        })
      return filteredData
    }
  }, [serviceTypes])

  const debouncedFiltering = useMemo(() => {
    return debounce(text => {
      if (text === null) {
        setFilteredServiceTypes(serviceTypes)
      }
      const filteredData = globalSearchMemo(text || '', serviceTypes)
      setFilteredServiceTypes(filteredData)
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 0)
    }, 0)
  }, [globalSearchMemo])

  useEffect(() => {
    debouncedFiltering(searchValue)
  }, [searchValue, debouncedFiltering])

  return (
    <div
      className="absolute bg-black/50 z-[9999] w-full h-full top-0 right-0 flex justify-center items-center"
      ref={popupRef}
      onClick={e => {
        if (e?.target !== popupRef?.current) return
        setShowServicesModal(false)
      }}
    >
      <div className="lg:w-11/12 lg:h-5/6 w-full h-full bg-white lg:rounded-lg flex flex-col overflow-hidden">
        <div className="flex items-center ">
          <button
            className="px-4"
            type="button"
            onClick={() => {
              setShowServicesModal(false)
              setSearchValue('')
            }}
          >
            <icons.ArrowLeft />
          </button>

          {!showAddServiceForm ? (
            <>
              <ServiceSelect
                placeholder="Pretraži..."
                onChange={e => setSearchValue(e.target.value)}
                ref={inputRef}
                value={searchValue}
                autoFocus={true}
              ></ServiceSelect>
              <ButtonTW
                label="+ Nova usluga"
                variant="primaryOutline"
                className="flex-0 mr-3"
                onClick={() => setShowAddServiceForm(true)}
              />
            </>
          ) : (
            <p className=" text-[18px]">Nova usluga</p>
          )}
        </div>

        {showAddServiceForm ? (
          <NewService setShowAddServiceForm={setShowAddServiceForm} />
        ) : serviceTypesLoading ? (
          <Loader isComponent />
        ) : filteredServiceTypes?.length ? (
          <div className="overflow-auto px-3 flex-1">
            {filteredServiceTypes
              ?.sort(
                (a, b) =>
                  Number(a?.sequence)
                    ?.toString()
                    ?.localeCompare(Number(b.sequence).toString(), undefined, {
                      numeric: true,
                    })
              )
              ?.map(type => {
                return (
                  <div key={type?.id}>
                    <button
                      className={`uppercase py-[10px] text-[16px] text-zoyya-secondaryDark font-semibold   px-2 rounded-md w-full flex flex-1 my-1 p-1  border border-transparent  outline-0 ${
                        loyaltyType === 'giftcard'
                          ? 'focus-visible:bg-zoyya-lightBackground  hover:bg-zoyya-lightBackground cursor-pointer focus-visible:border focus-visible:border-purple-500'
                          : ''
                      }`}
                      onClick={async () => {
                        if (loyaltyType === 'giftcard') {
                          setSearchValue('')
                          setShowServicesModal(false)

                          onClickItem({
                            itemName: type?.name,
                            serviceId: null,
                            serviceTypeId: BigInt(type?.id),
                            description: '',
                            discountRate: 0,
                            quantity: 1,
                          })
                        }
                      }}
                    >
                      {type.name}
                    </button>
                    <div>
                      {type?.Services?.slice()
                        ?.sort(
                          (a, b) =>
                            Number(a?.sequence)
                              ?.toString()
                              ?.localeCompare(
                                Number(b.sequence).toString(),
                                undefined,
                                {
                                  numeric: true,
                                }
                              )
                        )
                        ?.map(item => {
                          return (
                            <button
                              type="button"
                              key={item?.id}
                              className="px-[15px] cursor-pointer hover:bg-zoyya-lightBackground rounded-md w-full flex flex-1 my-1 p-1 focus-visible:bg-zoyya-lightBackground  border border-transparent focus-visible:border focus-visible:border-purple-500 outline-0"
                              onClick={async () => {
                                if (loyaltyType === 'giftcard') {
                                  setSearchValue('')
                                  setShowServicesModal(false)
                                  onClickItem({
                                    itemName: item?.name,
                                    serviceId: BigInt(item?.id),
                                    serviceTypeId: null,
                                    description: item?.description,
                                    discountRate: 0,
                                    quantity: 1,
                                  })
                                } else {
                                  setSelectedService(item?.id)
                                }
                              }}
                            >
                              <div className="min-w-200 mr-auto text-start">
                                <div>{item.name}</div>

                                <span
                                  className="text-sm text-gray-500 tracking-wider"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                />
                              </div>
                              <div className="flex flex-col lg:flex-row items-center ml-3">
                                {selectedService === item?.id ? (
                                  <div className="flex items-center mr-[5px]">
                                    <span className="mr-[8px]">Kol:</span>
                                    <input
                                      pattern="/^[0-9]+$/"
                                      autoFocus
                                      className="w-[70px] h-[32px] border-[1px] outline-none border-zoyya-primary rounded-[4px] p-[9px_8px_8px_8px]"
                                      name={`quantity_${item?.id}`}
                                      value={inputValue}
                                      onChange={e => {
                                        const value = e.target.value.replace(
                                          /[^\d]/,
                                          ''
                                        )

                                        if (parseInt(value) !== 0) {
                                          setInputValue(value)
                                        }
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="min-w-[102px] mr-[5px] shrink-0" />
                                )}

                                <p className="mr-0 shrink-0 w-[100px] leading-[32px] text-end self-center">
                                  {formatPriceNumberWithDelimiter(item.price) +
                                    ' ' +
                                    currency}
                                </p>
                                <div className="w-[70px] shrink-0 flex justify-end">
                                  {selectedService === item?.id ? (
                                    <ButtonTW
                                      disabled={
                                        !inputValue ||
                                        !isInteger(Number(inputValue))
                                      }
                                      icon={
                                        <icons.Add size="smaller" stroke={2} />
                                      }
                                      variant="primary"
                                      size="small"
                                      onClick={() => {
                                        setSearchValue('')
                                        setShowServicesModal(false)
                                        onClickItem({
                                          itemName: item?.name,
                                          serviceId: BigInt(item?.id),
                                          serviceTypeId: null,
                                          description: item?.description,
                                          discountRate:
                                            roundNumberWithTwoDecimals(
                                              Number(
                                                formRef?.values?.discountRate
                                              )
                                            ) / 100,
                                          quantity: Number(inputValue),
                                        })
                                      }}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </button>
                          )
                        })}
                    </div>
                  </div>
                )
              })}
          </div>
        ) : (
          <EmptyList title="Nema rezultata pretrage" />
        )}
      </div>
    </div>
  )
}
