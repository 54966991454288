import React from 'react'
import { useAppServices } from 'src/sdk/appServices'
import { GetOrganizationByIdDocument } from 'src/state/graphql'
import { trpc } from 'src/trpc'

export const Refetcher = ({ orgId, refetchOrg, refetchLocation }) => {
  const { apolloClient } = useAppServices()
  const refetchData = async () => {
    refetchOrg()
    await apolloClient.query({
      query: GetOrganizationByIdDocument,
      variables: { id: orgId },
      fetchPolicy: 'network-only',
    })
    refetchLocation()
  }

  trpc.onOrganizationUpdated.useSubscription(
    { orgId },
    {
      onData: () => refetchData(),
    }
  )

  return null
}
