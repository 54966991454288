import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg<any>`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const RobotHead = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.75,13.5A7.5,7.5,0,0,0,12.02,6a7.708,7.708,0,0,0-7.27,7.811V15a2,2,0,0,0,2,2h11a2,2,0,0,0,2-2Z" />
        <path d="M17.75,17h-11v.5a5.5,5.5,0,0,0,11,0Z" />
        <circle cx="9.25" cy="12.5" r="1.5" />
        <circle cx="15.25" cy="12.5" r="1.5" />
        <path d="M9.75,22.386V21.5a2.5,2.5,0,0,1,2.5-2.5h0a2.5,2.5,0,0,1,2.5,2.5v.886" />
        <path d="M19.482,16h.268a2,2,0,0,0,2-2h0a2,2,0,0,0-2-2H19.6" />
        <line x1="21.75" y1="14" x2="22.75" y2="14" />
        <path d="M4.959,12H4.75a2,2,0,0,0-2,2h0a2,2,0,0,0,2,2h.268" />
        <line x1="2.75" y1="14" x2="1.75" y2="14" />
        <path d="M11.75,6.017V4.5A1.5,1.5,0,0,1,13.25,3a1.5,1.5,0,0,0,1.5-1.5V1" />
      </g>
    </StyledIcon>
  )
}
