import { ButtonHTMLType } from 'antd/lib/button/button'
import React, { forwardRef } from 'react'
import { cls } from 'src/helpers'
import { icons } from 'components/Icon'
import { ToolTip } from 'src/components'

const classes = {
  base: 'flex w-fit items-center relative active:top-px focus:shadow-[0_0_0_3px_#ABB7FF] justify-center  focus:outline-none transition ease-in duration-50 rounded-[4px] text-md ',
  disabled:
    'cursor-not-allowed active:top-0 !bg-zoyya-grayLight !hover:bg-zoyya-grayLight !text-zoyya-text/40 !hover:text-zoyya-text/30 !border-zoyya-text/20 !hover:border-zoyya-text/20',
  size: {
    small: 'px-2.5 text-[13px] h-[32px]',
    medium: 'h-[40px] px-5 ',
    large: 'px-8 py-3 text-lg',
  },
  variant: {
    primary:
      'bg-zoyya-primary hover:bg-zoyya-primaryDark focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-white',
    secondary:
      'bg-white text-zoyya-secondaryDarker border border-zoyya-grayLight hover:text-zoyya-secondary focus:border-zoyya-primary focus:text-zoyya-secondary  hover:text-zoyya-secondary hover:border-zoyya-secondary',
    orangeOutline:
      'bg-white text-zoyya-accent5 border border-zoyya-accent5 hover:bg-white hover:border-[#fe8c4b] hover:text-[#fe8c4b]',
    text: 'bg-transparent text-zoyya-text hover:text-zoyya-secondaryDarker',
    primaryOutline:
      'bg-white text-zoyya-primary border border-zoyya-primary hover:bg-white hover:border-zoyya-primaryDark hover:text-zoyya-primaryDark',
    link: 'text-zoyya-primary bg-transparent hover:text-zoyya-primaryDark',
    secondaryGreen:
      'bg-white text-[#00ca79] border border-[#00ca79] hover:bg-white hover:border-[#008650] hover:text-[#008650]',
    secondaryRed:
      'bg-white text-[#fc5a5a] border border-[#fc5a5a] hover:bg-white hover:border-[#9D2637] hover:text-[#9D2637]',
  },
}

type ButtonProps = {
  label?: string
  type?: ButtonHTMLType
  className?: any
  variant?: keyof (typeof classes)['variant']
  size?: 'small' | 'medium' | 'large'
  pill?: any
  disabled?: boolean
  icon?: any
  onClick?: any
  name?: string
  isLoading?: boolean
  dropDown?: boolean
  onClickDropDown?: any
  onClickIcon?: any
  iconClassName?: any
  tooltip?: string
  iconRight?: boolean
}
export const ButtonTW = (props: ButtonProps) => {
  const {
    label,
    type = 'button',
    className,
    variant = 'primary',
    size = 'medium',
    disabled = false,
    icon,
    onClick,
    onClickDropDown,
    name,
    isLoading,
    dropDown,
    onClickIcon,
    iconClassName,
    tooltip,
    iconRight,
  } = props

  const baseContent = (
    <button
      disabled={disabled}
      type={type}
      className={cls(`
                ${classes.base}
                ${classes.size[size]}
                ${classes.variant[variant]}
                ${disabled && classes.disabled}
                ${className}
            `)}
      onClick={onClick}
      data-cy={`button_${name}`}
    >
      {isLoading && <Spinner buttonVariant={variant} />}
      {icon && !iconRight ? (
        <div
          className={`${label ? 'pr-3' : null} ${iconClassName}`}
          onClick={onClickIcon}
        >
          {icon}
        </div>
      ) : null}

      {dropDown ? (
        <div
          className=" py-[8px] px-[4px] border-l-[1px] border-inherit"
          onClick={onClickDropDown}
        >
          <icons.ChevDown size={'extraSmall'} stroke={4} />
        </div>
      ) : null}

      {label ? <span>{label}</span> : null}
      {icon && iconRight ? (
        <div
          className={`${label ? 'pl-3' : null} ${iconClassName}`}
          onClick={onClickIcon}
        >
          {icon}
        </div>
      ) : null}
    </button>
  )
  return tooltip ? (
    <ToolTip postition={'top'} label={tooltip} key={'tooltip'}>
      {baseContent}
    </ToolTip>
  ) : (
    baseContent
  )
}
const Spinner = ({
  buttonVariant,
}: {
  buttonVariant?: keyof (typeof classes)['variant']
}) => {
  return (
    <div>
      <div
        style={{ borderTopColor: 'transparent' }}
        className={`mr-2 w-6 h-6 border-2 ${
          buttonVariant === 'primary'
            ? 'border-white'
            : buttonVariant === 'primaryOutline'
            ? 'border-zoyya-primary'
            : 'border-white'
        } border-solid rounded-full animate-spin`}
      ></div>
    </div>
  )
}
