import React from 'react'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { RobotHead } from '../Icon/Icons/RobotHead'
import { useFormikContext } from 'formik'
import { trpc } from 'src/trpc'
import { useAppServices } from 'src/sdk/appServices'
type TServiceDescription = {
  entity: 'serviceDescription'
  name: string
  serviceTypeId: bigint
  durationMinutes: number
  price: number
  hasBookingConfirmation: boolean
}

export type TAIGenerateButtonProps = {
  orgId: string
  locationId: bigint
  fieldName: string
  disabled?: boolean
} & (
  | TServiceDescription
  | { entity: 'locationDescription' }
  | { entity: 'imageDescription'; serviceId?: bigint | null; title: string }
  | { entity: 'giftCardTemplateDescription'; giftCardTemplateId: bigint }
  | { entity: 'giftCardDescription'; giftCardId: bigint }
)

export const AIGenerateButton = (props: TAIGenerateButtonProps) => {
  const { fieldName, entity } = props
  const { toast } = useAppServices()
  const formikContext = useFormikContext()

  const generateServiceDescriptionMutation =
    trpc.aiGenerator_generateServiceDescription.useMutation()
  const generateLocationDescriptionMutation =
    trpc.aiGenerator_generateLocationDescription.useMutation()
  const generateGiftCardTemplateDescriptionMutation =
    trpc.aiGenerator_generateGiftCardTemplateDescription.useMutation()
  const generateGalleryImageDescriptionMutation =
    trpc.aiGenerator_generateGalleryImageDescription.useMutation()

  const isLoading =
    generateServiceDescriptionMutation.isLoading ||
    generateLocationDescriptionMutation.isLoading ||
    generateGiftCardTemplateDescriptionMutation.isLoading ||
    generateGalleryImageDescriptionMutation.isLoading

  const handleGenerateDescription = async () => {
    try {
      const description =
        entity === 'serviceDescription'
          ? await generateServiceDescriptionMutation.mutateAsync({
              name: props.name,
              durationMinutes: Number(props.durationMinutes),
              price: Number(props.price),
              hasBookingConfirmation: props.hasBookingConfirmation,
              serviceTypeId: BigInt(props.serviceTypeId),
              locationId: BigInt(props.locationId),
              orgId: props.orgId,
            })
          : entity === 'locationDescription'
          ? await generateLocationDescriptionMutation.mutateAsync({
              locationId: BigInt(props.locationId),
              orgId: props.orgId,
            })
          : entity === 'giftCardTemplateDescription'
          ? await generateGiftCardTemplateDescriptionMutation.mutateAsync({
              giftCardTemplateId: BigInt(props.giftCardTemplateId),
              locationId: BigInt(props.locationId),
              orgId: props.orgId,
            })
          : entity === 'imageDescription'
          ? await generateGalleryImageDescriptionMutation.mutateAsync({
              serviceId: props.serviceId ? BigInt(props.serviceId) : null,
              title: props.title,
              locationId: BigInt(props.locationId),
              orgId: props.orgId,
            })
          : ''
      if (description) {
        formikContext.setFieldValue(fieldName, description)
      }
    } catch (err) {
      console.log(err)
      toast.danger((err as any).message || 'Došlo je do pogreške')
    }
  }

  return (
    <ButtonTW
      size="small"
      label="Generiraj pomoću AI-a"
      variant="primaryOutline"
      icon={<RobotHead size="small" />}
      isLoading={isLoading}
      disabled={props.disabled}
      onClick={async e => {
        if (isLoading) return
        e.stopPropagation()
        e.preventDefault()
        await handleGenerateDescription()
      }}
    />
  )
}
