import warning from 'components/Icon/Icons/Warning'
import briefcase from 'components/Icon/Icons/Briefcase'
import userBold from 'components/Icon/Icons/UserBold'
import { Employees } from 'components/Icon/Icons/Employees'
import { WorkHours } from 'components/Icon/Icons/WorkHours'
import { Services } from 'components/Icon/Icons/Services'
import { OnlineBooking } from 'components/Icon/Icons/OnlineBooking'
import { Holidays } from 'components/Icon/Icons/Holidays'
import { Professions } from 'components/Icon/Icons/Professions'
import { ArrowLeft } from 'components/Icon/Icons/ArrowLeft'
import { ArrowRight } from 'components/Icon/Icons/ArrowRight'
import { ArrowRightThick } from 'components/Icon/Icons/ArrowRightThick'
import { Clock } from 'components/Icon/Icons/Clock'
import { HouseBold } from 'components/Icon/Icons/HouseBold'
import { ArrowNext } from 'components/Icon/Icons/ArrowNext'
import { ChevronLeft } from 'components/Icon/Icons/ChevronLeft'
import { ChevronRight } from 'components/Icon/Icons/ChevronRight'
import { Add } from 'components/Icon/Icons/Add'
import { PasswordLock } from 'components/Icon/Icons/PasswordLock'
import Logout from 'components/Icon/Icons/Logout'
import dashboardIcon from 'components/Icon/Icons/Dashboard'
import calendarIcon from 'components/Icon/Icons/Calendar'
import User from 'components/Icon/Icons/User'
import { Edit } from 'components/Icon/Icons/Edit'
import Location from 'components/Icon/Icons/Location'
import { Billing } from 'components/Icon/Icons/Billing'
import Clients from 'components/Icon/Icons/Clients'
import { Chart } from 'components/Icon/Icons/Chart'
import { PhoneDownload } from 'components/Icon/Icons/PhoneDownload'
import { Gift } from 'components/Icon/Icons/Gift'
import { Eye } from 'components/Icon/Icons/Eye'
import { EyeDisabled } from 'components/Icon/Icons/EyeDisabled'
import { WhatsApp } from 'components/Icon/Icons/WhatsApp'
import { Sms } from 'components/Icon/Icons/Sms'
import { Copy } from 'components/Icon/Icons/Copy'
import { Messages } from 'components/Icon/Icons/Messages'
import Share from 'components/Icon/Icons/Share'
import { Hand } from 'components/Icon/Icons/Hand'
import Gear from 'components/Icon/Icons/Gear'
import ChevronDown from 'components/Icon/Icons/ChevronDown'
import { SyncArrowsSquare } from 'components/Icon/Icons/SyncArrowsSquare'
import { Hourglass } from 'components/Icon/Icons/Hourglass'
import { Error } from 'components/Icon/Icons/Error'
import { Info } from 'components/Icon/Icons/Info'
import { CheckBox } from 'components/Icon/Icons/CheckBox'
import { Login } from 'components/Icon/Icons/Login'
import { Register } from 'components/Icon/Icons/Register'
import HamburgerMenu from 'components/Icon/Icons/HamburgerMenu'
import { Email } from 'components/Icon/Icons/Email'
import LogoutIcon from 'components/Icon/Icons/Logout'
import { Download } from 'components/Icon/Icons/Download'
import { CreditCard } from 'components/Icon/Icons/CreditCard'
import { Star } from 'components/Icon/Icons/Star'
import { SpanishFlag } from 'components/Icon/Icons/SpanishFlag'
import { CroatianFlag } from 'components/Icon/Icons/CroatianFlag'
import { UKFlag } from 'components/Icon/Icons/UKFlag'
import { Chat } from 'components/Icon/Icons/Chat'
import { GermanFlag } from 'components/Icon/Icons/GermanFlag'
import { CheckMarkIcon } from 'components/Icon/Icons/Checkmark'
import { Light } from 'components/Icon/Icons/Light'
import { Shop } from 'components/Icon/Icons/Shop'
import { Group } from 'components/Icon/Icons/Group'
import { Intercom } from 'components/Icon/Icons/Intercom'
import { SafariShare } from 'components/Icon/Icons/SafariShare'
import Cross from 'components/Icon/Icons/Cross'
import { NewLocation } from 'components/Icon/Icons/NewLocation'
import { NewWindow } from 'components/Icon/Icons/NewWindow'
import Flash from 'components/Icon/Icons/Flash'
import { CheckFilled } from 'components/Icon/Icons/CheckFilled'
import { Bell } from 'components/Icon/Icons/Bell'
import { Trash } from 'components/Icon/Icons/Trash'
import { More } from 'components/Icon/Icons/More'
import { ThreeDots } from 'components/Icon/Icons/ThreeDots'
import { PhoneBold } from 'components/Icon/Icons/PhoneBold'
import { Notes } from 'components/Icon/Icons/Notes'
import { ChevDown } from 'components/Icon/Icons/ChevDown'
import { RatingStar } from 'components/Icon/Icons/RatingStar'
import { SendEmail } from 'components/Icon/Icons/SendEmail'
import { ThreeDotsVertical } from 'components/Icon/Icons/ThreeDotsVertical'
import { EmailCheck } from 'components/Icon/Icons/EmailCheck'
import { MessagesBubbleCheck } from 'components/Icon/Icons/MessagesBubbleCheck'
import { ChartArrowUp } from 'components/Icon/Icons/ChartArrowUp'
import { ChartArrowDown } from 'components/Icon/Icons/ChartArrowDown'
import { CalendarSearch } from 'components/Icon/Icons/CalendarSearch'
import { CalendarSearch2 } from 'components/Icon/Icons/CalendarSearch2'
import { TaskListSearch } from 'components/Icon/Icons/TaskListSearch'
import { Google } from 'components/Icon/Icons/Google'
import { Day } from 'components/Icon/Icons/Day'
import { ThreeDay } from 'components/Icon/Icons/ThreeDay'
import { Week } from 'components/Icon/Icons/Week'
import Search from 'components/Icon/Icons/Search'
import { WarningTriangle } from 'components/Icon/Icons/WarningTriangle'
import { SerbianFlag } from 'components/Icon/Icons/SerbianFlag'
import { Resources } from 'components/Icon/Icons/Resources'
import { CardCanceled } from 'components/Icon/Icons/CardCanceled'
import SearchIcon from 'components/Icon/Icons/Search'
import { Check } from 'components/Icon/Icons/Check'
import { ArrowUpDrop } from 'components/Icon/Icons/ArrowUpDrop'
import { ArrowDownDrop } from 'components/Icon/Icons/ArrowDownDrop'
import { WifiWarning } from 'components/Icon/Icons/WifiWarning'
import { Globe } from 'components/Icon/Icons/Globe'
import { Coins } from 'components/Icon/Icons/Coins'
import { PDF } from 'components/Icon/Icons/PDF'
import { Print } from 'components/Icon/Icons/Print'
import { CashRegister } from 'components/Icon/Icons/CashRegister'
import { Lock } from 'components/Icon/Icons/Lock'
import { DoubleCheck } from 'components/Icon/Icons/DoubleCheck'
import { VivaWallet } from 'components/Icon/Icons/VivaWallet'
import { ChevronDownRounded } from './Icons/ChevronDownRounded'
import { Refresh } from './Icons/Refresh'
import { ZoomIn } from './Icons/ZoomIn'
import { ZoomOut } from './Icons/ZoomOut'
import { Birthday } from './Icons/Birthday'
import { Products } from 'components/Icon/Icons/Products'
import { NoShowsBook } from 'components/Icon/Icons/NoShowsBook'
import { ReviewsBook } from 'components/Icon/Icons/ReviewsBook'
import { VisitsBook } from 'components/Icon/Icons/VisitsBook'
import { Graph } from 'components/Icon/Icons/Graph'
import { Cancellation } from 'components/Icon/Icons/Cancellation'
import { MultipleAdd } from 'components/Icon/Icons/MultipleAdd'
import { Warehouse } from 'components/Icon/Icons/Warehouse'
import { Changelog } from 'components/Icon/Icons/Changelog'
import { Support } from 'components/Icon/Icons/Support'
import { WaitingResults } from './Icons/WaitingResults'
import { ProcedureApproved } from './Icons/ProcedureApproved'
import { ProcedureCanceled } from './Icons/ProcedureCanceled'
import { Discount } from './Icons/Discount'
import { RobotHead } from './Icons/RobotHead'

export const icons = {
  Messages,
  Add,
  ArrowRight,
  ArrowRightThick,
  ArrowNext,
  Briefcase: briefcase,
  ChevronLeft,
  ChevronRight,
  Edit,
  Copy,
  UserBold: userBold,
  WorkHours,
  Services,
  OnlineBooking,
  Logout,
  Holidays,
  PasswordLock,
  Eye,
  EyeDisabled,
  Share,
  Professions,
  ArrowLeft,
  Clock,
  DashboardIcon: dashboardIcon,
  CalendarIcon: calendarIcon,
  Employees,
  HouseBold,
  User,
  Location,
  Billing,
  Clients,
  Chart,
  PhoneDownload,
  Gift,
  Sms,
  WhatsApp,
  Hand,
  Gear,
  ChevronDown,
  SyncArrowsSquare,
  Hourglass,
  Error,
  Info,
  CheckBox,
  Login,
  Register,
  HamburgerMenu,
  Email,
  LogoutIcon,
  Download,
  CreditCard,
  Star,
  SpanishFlag,
  CroatianFlag,
  UKFlag,
  Chat,
  GermanFlag,
  CheckMarkIcon,
  Light,
  Shop,
  Group,
  Intercom,
  SafariShare,
  Cross,
  NewLocation,
  NewWindow,
  Flash,
  CheckFilled,
  Bell,
  Trash,
  More,
  ThreeDots,
  PhoneBold,
  Notes,
  ChevDown,
  RatingStar,
  SendEmail,
  ThreeDotsVertical,
  MessagesBubbleCheck,
  EmailCheck,
  ChartArrowUp,
  ChartArrowDown,
  CalendarSearch,
  CalendarSearch2,
  TaskListSearch,
  Google,
  Day,
  ThreeDay,
  Week,
  WarningTriangle,
  Search,
  SerbianFlag,
  Resources,
  CardCanceled,
  SearchIcon,
  Check,
  ArrowUpDrop,
  ArrowDownDrop,
  WifiWarning,
  Globe,
  Coins,
  PDF,
  Print,
  CashRegister,
  Lock,
  DoubleCheck,
  VivaWallet,
  ChevronDownRounded,
  Refresh,
  ZoomIn,
  ZoomOut,
  Birthday,
  Products,
  NoShowsBook,
  ReviewsBook,
  VisitsBook,
  Graph,
  Cancellation,
  MultipleAdd,
  Warehouse,
  Changelog,
  Support,
  WaitingResults,
  ProcedureApproved,
  ProcedureCanceled,
  Discount,
  RobotHead,
}

export const Warning = warning
